import Confetti from "../../components/Confetti/index";
import Gallery from "../../components/Gallery/index";
import React from "react";
import SecondSection from "../../components/SecondSection/index";
import Wishes from "../../components/Wishes";
const Homepage = () => {
  return (
    <div>
      <Confetti />
      <SecondSection />
      <Gallery />
      <Wishes />
    </div>
  );
};

export default Homepage;
