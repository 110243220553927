import * as giftBox from "../../animations/gift-box.json";

import { Col, Container, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

import Lottie from "react-lottie";
import React from "react";
import { bounce } from "react-animations";

const Section = styled.div`
  height: 800px;
  background-color: #f06292;
  color: white;
  padding: 50px;
`;
const BouncedText = styled.div`
  animation: 2s ${keyframes`${bounce}`};
  color: white;
`;

const SecondSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: giftBox.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <Section>
      <Container fluid>
        <Row style={{ marginTop: 250 }}>
          <Col>
            <BouncedText className="text-center">
              <h2>Γι'αυτό και εμείς έχουμε ετοιμάσει κάτι για εσένα!! 🎁</h2>
              <p style={{ fontSize: 24 }}>
                Άν θέλεις να δεις τί έχουμε ετοιμάσει scrollαρε προς τα κάτω 😁
              </p>
            </BouncedText>
          </Col>
        </Row>
        <Row>
          <Col>
            <Lottie width={400} height={200} options={defaultOptions} />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default SecondSection;
