import ConfettiGenerator from "confetti-js";
import React from "react";

const Homepage = () => {
  React.useEffect(() => {
    const confettiSettings = { target: "confetti", max: 200 };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []);

  return (
    <div>
      <section>
        <div id="wrapper">
          <div style={{ position: "relative" }}>
            <canvas id="confetti"></canvas>
          </div>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <div style={{ position: "relative", textAlign: "center" }}>
              <h1
                style={{ fontWeight: 500, textTransform: "uppercase"}}
              >
                🎉👏Συγχαρητηριαααα!!!👏🎉
              </h1>
              <p style={{ fontSize: "1.5em" }}>
                Μας έκανες όλους υπερήφανούυυυς!!!😭😭
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homepage;
