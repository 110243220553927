import { Card, Container } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import React, { useCallback, useState } from "react";

import Gallery from "react-photo-gallery";
import photos from "../../photos";

const LighBoxGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Container
      fluid
      style={{ backgroundColor: "#ab47bc", padding: 100, color: "white" }}
    >
      <div className="animated bounce">
        <h3 className="text-center">
          Αυτές είναι κάποιες από τις φωτογραφίες μας μαζί σου αυτά τα υπέροχα 4
          χρόνια! 🎥
        </h3>
        <h4 className="text-center">
          Σε ευχαριστούμε για όλες αυτές τις υπέροχες στιγμές που μας έχεις
          χαρίσει! ❤
        </h4>
      </div>
      <Card style={{ marginTop: 40 }} className="animated bounce">
        <Gallery photos={photos} onClick={openLightbox} />
      </Card>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  );
};

export default LighBoxGallery;
