import React, { useState } from "react";

import { Card } from "react-bootstrap";

const WishCard = ({ img, text, title }) => {
  const [visible, setVisible] = useState(false);
  const [visibleClickText, setVisibleClickText] = useState(false);

  return (
    <Card
      style={{
        boxShadow:
          "0 4px 4px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08)",
        color: "black"
      }}
    >
      <div
        style={{ position: "relative", textAlign: "center" }}
        onMouseEnter={() => setVisibleClickText(true)}
        onMouseLeave={() => setVisibleClickText(false)}
      >
        <div></div>
        <Card.Img
          style={{ height: 300 }}
          onClick={() => setVisible(!visible)}
          variant="top"
          src={img}
        />
        <div
          style={{
            display: visibleClickText ? "block" : "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white"
          }}
        >
          <h2 onClick={() => setVisible(!visible)}>{title}</h2>
        </div>
      </div>
      <div style={{ display: visible ? "block" : "none" }}>
        <Card.Title style={{ padding: visible ? "10px 20px" : 0 }}>
          {title}
        </Card.Title>
        <Card.Body>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </div>
    </Card>
  );
};

export default WishCard;
